function mixLines(line1, line2, line3, rightAnswer, funcIfRight, funcIfWrong) {
    const rndNb = Math.floor(Math.random() * 3);
    const rndNb2 = Math.floor(Math.random() * 3);
    const linesArr = [line1, line2, line3];
    const tempLine = linesArr[rndNb];
    linesArr[rndNb] = linesArr[rndNb2];
    linesArr[rndNb2] = tempLine;

    return (
        <>
            <h6>Pick the right translation</h6>
        {linesArr.map((line) => (
                <button class="button width-100p three-button" onClick= {() => {(line === rightAnswer) ? funcIfRight() : funcIfWrong()}}>{line}</button>
        ))}
        </>
    );
}

function feedback(wbw, comment) {
    if (wbw === "") {
        if (comment === "") {
            return (
                <>
                <br /> <br />
                <br />
                </>
            );
        } else {
            return (
                <>
                <br /> <br />
                Comm: {comment} <br />
                </>
            );
        }
    } else if (comment === "") {
        return (
            <>
                <br /> Wbw: {wbw} <br />
                <br />
            </>
        );
    } else {
        return (
            <>
                <br /> Wbw: {wbw} <br />
                Comm: {comment} <br />
            </>
        );
    }
}



function AnswerBox({ hasAnswered, quizType, line1, line2, line3, rightAnswer, wbw, comment, isAnswerRight, funcIfRight, funcIfWrong, funcContinue }) {
    if (!hasAnswered) {
        switch(quizType) {
            case "pick-3":
                return(
                    mixLines(line1, line2, line3, rightAnswer, funcIfRight, funcIfWrong)
                );
            case "make-line":
                break;
            default:
                return null;
        }
    } else if (isAnswerRight) {
        // green screen
        return (
            <>
            <div class="alert has-icon alert-right-answer">
                {/* <div class="alert-svg">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M39.1 77l48.7-48.7c1.5-1.4 1.6-3.8.2-5.3-1.4-1.5-3.8-1.6-5.3-.2l-.2.2-46.1 46.1-19.8-19.9c-1.5-1.4-3.9-1.3-5.3.2-1.3 1.4-1.3 3.7 0 5.1L33.8 77c1.4 1.5 3.8 1.5 5.3 0z"></path></svg>
                </div> */}
                <button class="button style-success continue-button" onClick={funcContinue}>Continue</button>
                <h6>Bingpot! You got it right!</h6>
                <p>"{rightAnswer}"<br />
                {feedback(wbw, comment)}
                {/* Word-by-word: {wbw} <br />
                Comment: {comment} <br /> <br /> */}
                </p>
                
            </div>
            </>
        );
    } else {
        // red screen
        return (
            <div class="alert has-icon alert-wrong-answer">
                {/* <div class="alert-svg">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.72,18,13.82,4a2.09,2.09,0,0,0-3.64,0L2.28,18a2.08,2.08,0,0,0,1.81,3.11H19.91A2.08,2.08,0,0,0,21.72,18Zm-1.81,1.11H4.09L4,18.94l8-14a.1.1,0,0,1,.08,0h0L20,19h0ZM11,14V11a1,1,0,0,1,2,0v3a1,1,0,0,1-2,0Zm2,3a1,1,0,1,1-1-1A1,1,0,0,1,13,17Z"/></svg>
                </div> */}
                <button class="button style-danger continue-button" onClick={funcContinue}>Continue</button>
                <h6>Nope! Correct answer:</h6>
                <p>"{rightAnswer}"<br />
                {feedback(wbw, comment)}
                {/* {comment} <br /> <br /> */}
                </p>
                
            </div>
        );
    }

}

export default AnswerBox