import React, { useState, useEffect, useRef } from "react";
import "./css/Tree.css";

// js-styles
import LessonButton from "./LessonButton.js";
import Lesson from "./Lesson.js";
import Boss from "./Boss.js";
import BossButton from "./BossButton.js";

// for Boss
// elemental 70
// pinocchio 46
// zog 41
// === lines.length - 1



const Tree8 = ({ treeName, onCloseTree, initStates, saveStates }) => {
    // states
    //const [treeName, setTreeName] = useState("Elemental");
    //const [pageNb, setPageNb] = useState(1);

    //const [startLesson, setStartLesson] = useState(false);
    const [lessonNb, setLessonNb] = useState(0); // lesson_0 == display tree // -1 == Boss

    // consts
    const nbOfLessons = 8;
    const lessonsNbs = useRef([]);
    const buttonsStates = useRef([]);
    const buttonsUnlock = useRef([
        //[1], [2], [3, 4], [8], [2, 7], [6], [7], [4], []
        [1], [2], [3], [4], [8], [6], [7], [3]
    ]);

   /*  useEffect(() => {
        for (let i = 1; i < nbOfLessons + 1; i++) {
            lessonsNbs.current.push(i);
        }
    }, []); */

    if (lessonsNbs.current.length === 0) {
        for (let i = 1; i < nbOfLessons + 1; i++) {
            lessonsNbs.current.push(i);
            buttonsStates.current.push("blocked");
        }
        buttonsStates.current.push("blocked");
        buttonsStates.current[0] = "available";
        buttonsStates.current[5] = "available";

        buttonsStates.current = initStates;
    }

    /* useEffect(() => {
        buttonsStates.current[0] = "available";
        buttonsStates.current[5] = "available";
    }, []); */

    //alert(lessonsNbs.current);


    // functions
    function onCloseFunc() {
        setLessonNb(0);
    }

    function lessonSuccess() {
        buttonsStates.current[lessonNb - 1] = "done";
        
        buttonsUnlock.current[lessonNb - 1].forEach((path) => {
            if (buttonsStates.current[path] === "blocked") buttonsStates.current[path] = "available";
        });
        setLessonNb(0);
    }

    function bossSuccess() {
        buttonsStates.current[buttonsStates.current.length - 1] = "done";
        setLessonNb(0);
    }

    function closeTree() {
        saveStates(treeName, buttonsStates.current);
        onCloseTree();
    }

    


    // create an onCompletion function to pass to Lesson
    // this function will be triggered once Lesson is finished
    // we'll put the transition screens there
    // green with fireworks if success
    // red with funny quote if failure
    // updated LessonButton to success
    // so create a state array with all the states of the buttons? YES.


    // render


    if (lessonNb > 0) {
        return (
            <Lesson treeName={treeName} userLang="eng" lessonNb={lessonNb} onCloseFunc={onCloseFunc} successFunc={lessonSuccess} />
        );
    } else if (lessonNb === -1) {
        return (
            <Boss treeName={treeName} onCloseFunc={onCloseFunc} successFunc={bossSuccess} />
        );
    } else {
        return (
            <>
                <div className="main-page">
                    <div className="tree-page">
                        {/* top line */}
                        <div className="top-line-close-button-holder">
                            <button class="close-button" onClick={closeTree} aria-label="Close"></button>
                        </div>
                        <div className="top-line-title">
                            <h6>{treeName}</h6>
                        </div>
                        {/* <div className="top-line-pages">
                            <p>#section {pageNb}/3</p>
                        </div> */}

                        {/* lines with lessons */}
                        {lessonsNbs.current.map((lessonId) => (
                            <LessonButton state={buttonsStates.current[lessonId - 1]} lessonNb={lessonId} onClickFunc={() => {setLessonNb(lessonId)}}/>
                        ))}

                        {/* last line with boss */}
                        <BossButton state={buttonsStates.current[buttonsStates.current.length - 1]} onClickFunc={() => {setLessonNb(-1)}} />

                    </div>
                </div>
            </>

        );
    }
};

export default Tree8;