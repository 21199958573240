import React, { useRef, useEffect } from 'react';

// img
import OakSrc from "../img/oak-128.png";

const BossButton = ({ state, onClickFunc }) => {


    // consts
    const blueClass = "button style-accent";
    const greyClass = "button style-light";
    const purpleClass = "button style-purple";

    const bossClass = (() => {
        switch(state) {
            case "blocked":
                return greyClass;
            case "available":
                return blueClass;
            case "done":
                return purpleClass;
            default:
                return "";
        }
    });

    return (
        <div className="boss-button-holder">
            <button disabled={bossClass() === greyClass} class={bossClass()} onClick={onClickFunc}>
                <div className="boss-img-holder">
                    <img src={OakSrc} alt="oak" />
                </div>
            </button>
        </div>
    );
};

export default BossButton;