import React, { useState, useEffect, useRef } from "react";

import "./css/Lesson.css";

// js-styles
import LessonProgress from "../js-styles/LessonProgress.js";
import AnswerBox from "../js-styles/AnswerBox.js";

// img
import HeartSrc from "../img/heart.png";

// content

import lines_z from "../content/french/youtube/brut/zog.json";
import lines_e from "../content/french/youtube/disneyFR/elementaire.json";
import lines_p from "../content/french/youtube/netflixFR/pinocchio.json";


// video module
import "../../node_modules/video-react/dist/video-react.css";
import { BigPlayButton, ControlBar, LoadingSpinner, Player } from 'video-react';

//import "./App.css";
//import "@aws-amplify/ui-react/styles.css";

import { getUrl } from 'aws-amplify/storage';


//const DIFFR_LINES_NB = 5;
//const LESSON_LENGTH = 15;

const Boss = ({ treeName, onCloseFunc, successFunc }) => {
  // states
  const [userProgress, setUserProgress] = useState(0); // percentage int
  const [userHearts, setUserHearts] = useState(3);

  const [lineInd, setLineInd] = useState(1);

  const [hasAnswered, setHasAnswered] = useState(false);

  // refs
  const videoRef = useRef();
  const coco = useRef(); // to cancel setTimeout to stop looping the video

  //const NB_LINES = useRef(Object.values(lines).length);// Object.values(lines).length;
  const pre_key = useRef("");
  const lines = useRef();

  if (pre_key.current === "") {
    switch(treeName) {
      case "Pinocchio":
        pre_key.current = "french/youtube/netflixFR/pinocchio/";
        lines.current = Object.values(lines_p);
        break;
      case "Elemental":
        pre_key.current = "french/youtube/disneyFR/elementaire/";
        lines.current = Object.values(lines_e);
        break;
      case "ZOG":
        pre_key.current = "french/youtube/brut/zog/";
        lines.current = Object.values(lines_z);
        break;
    }
  }

  const NB_LINES = useRef(lines.current.length);

  //const urls = useRef([]); // or useMemo?
  //const linesNbs = useRef([]); // or useMemo?
  //let videoUrl = "";
  const videoUrl = useRef("");
  const isAnswerRight = useRef(false);

  /* if (urls.current.length === 0) {
    fetchUrls();
  } */

  //const lineNb = linesNbs.current[lineInd];
  //const videoUrl = urls.current[lineInd];
  const rightAnswer = lines.current[lineInd].translation;
  const wbw = lines.current[lineInd].wbw;
  const comment = lines.current[lineInd].comment;

  useEffect(() => {
    //setLineInd(0);
    fetchUrl();
    videoRef.current.volume = 0.75;
  }, []);

  /* useEffect(() => {
    fetchUrl();
  }, [lineInd]); */

  async function fetchUrl() {
    const temp_url = videoUrl.current;
    if (videoUrl.current === "") {
      const url = await getUrl({ key: pre_key.current + lines.current[0].line + ".mp4" });
      videoUrl.current = url.url;
      setLineInd(0);
    } else {
      const url = await getUrl({ key: pre_key.current + lines.current[(lineInd + 1) % NB_LINES.current].line + ".mp4" });
      videoUrl.current = url.url;
    }
    //if (temp_url === "") setLineInd(0);
    //if (lineInd === 0) setLineInd(1);
    //setLineInd(lineInd + 1);
  }


  /* async function fetchUrls() {
    for (let i = 0; i < NB_LINES.current; i++) {
      //const url = await getUrl({ key: "french/youtube/disneyFR/elementaire/" + lines[i].line + ".mp4" });
      const url = await getUrl({ key: pre_key.current + lines.current[i].line + ".mp4" });
      urls.current.push(url.url);
      //urls.current.push("https://media.w3.org/2010/05/sintel/trailer_hd.mp4");
    }
    setLineInd(0); // to force React to load very first video in user session
  } */

  function quitLesson() {
    stopButton();
    onCloseFunc();
  }

  /// video loop control buttons
  function playButton() {
    clearTimeout(coco.current) // in case user press Play when video is already looping
    videoRef.current.play();
    coco.current = setTimeout(() => { loopBuffer(); }, videoRef.current.duration);
  }

  function replayButton() {
    videoRef.current.play();
    coco.current = setTimeout(() => { reloopBuffer(); }, videoRef.current.duration);
  }

  function loopBuffer() {
    coco.current = setTimeout(() => { replayButton(); }, 500);
  }

  function reloopBuffer() {
    coco.current = setTimeout(() => { playButton(); }, 500);
  }

  function stopButton() {
    clearTimeout(coco.current);
    videoRef.current.pause();
  }

  /// for AnswerBox
  function pickRandomLine() {
    let rndLine = Math.floor(Math.random() * NB_LINES.current);
    return(lines.current[rndLine].translation);
  }

  function isRightAnswer() {
    isAnswerRight.current = true;
    setHasAnswered(true);
    fetchUrl();
  }

  function isWrongAnswer() {
    isAnswerRight.current = false;
    setHasAnswered(true);
    fetchUrl();
    
  }

  function clickContinue() {
    stopButton();

    if (isAnswerRight.current) {
      let newProgress = userProgress + 100 / NB_LINES.current;
      if (newProgress < 100) {
        setUserProgress(newProgress);
      } else {
        successFunc();
      }
    } else {
      if (userHearts > 0) {
        setUserHearts(userHearts - 1);
      } else {
        quitLesson();
      }
    }

    setHasAnswered(false);
    setLineInd((lineInd + 1) % NB_LINES.current);
    
  }


  return (
    <>
      <div className="main-page">
        <div className="lesson-page">
          <div className="top-line">
            <button class="close-button top-line-close-button" aria-label="Close" onClick={quitLesson}></button>
            <div className="top-line-progress">
              <LessonProgress percentage={userProgress} style="boss" />
            </div>
            <div className="top-line-hearts">
              <img src={HeartSrc} alt="heart"></img>
              <p>{userHearts}</p>
            </div>
          </div>

          <div className="video-div">
            <Player
              className="video-player"
              ref={videoRef}
              preload="auto"
              fluid={false}
              playsInline
              src={videoUrl.current}
            >
              <BigPlayButton disabled={true} position="center"></BigPlayButton>
              <LoadingSpinner disabled={true} />
              <ControlBar disabled={true} autoHide={false} disableDefaultControls />
            </Player>
          </div>

          <div className="control-buttons">
            <button class="button style-accent width-30p" onClick={playButton}>Play</button>
            <button class="button style-accent width-30p" onClick={stopButton}>Stop</button>
          </div>
          
          <div className="answer-box">
          <AnswerBox
            hasAnswered={hasAnswered}
            quizType={"pick-3"}
            line1={rightAnswer}
            line2={pickRandomLine()}
            line3={pickRandomLine()}
            rightAnswer={rightAnswer}
            wbw={wbw}
            comment={comment}
            isAnswerRight={isAnswerRight.current}
            funcIfRight={isRightAnswer}
            funcIfWrong={isWrongAnswer}
            funcContinue={clickContinue}
          />
          </div>
        </div>
      </div>
    </>
  );
};

export default Boss;