import "./css/Menu.css";

// img
import PropaneSrc from "../img/propane-128.png";

const Menu = ({ onClickPinocchio, onClickElemental, onClickZog, onClickCredits }) => {
    return (
        <>
            <div className="main-page" >
                <div className="menu-page">
                    <div className="logo-holder">
                        <img src={PropaneSrc} alt="logo" />
                    </div>

                    <div className="name-holder">
                        <h2>LingoLoops</h2>
                    </div>

                    <div className="desc-holder">
                        <h6>Learn French with videos</h6>
                    </div>

                    <div className="constr-holder">
                        <h6>/!\ Website in construction /!\</h6>
                    </div>

                    {/*

                    <div className="menu-button-holder-1">
                        <button class="button style-accent" onClick={onClickPinocchio}>
                            Pinocchio<br/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                        </button>
                    </div>

                    <div className="menu-button-holder-2">
                        <button class="button style-accent" onClick={onClickElemental}>
                            Elemental<br/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                        </button>
                    </div>

                    <div className="menu-button-holder-3">
                        <button class="button style-accent" onClick={onClickZog}>
                            ZOG<br />
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                            <i class="fa style-white fa-star" aria-hidden="true"/>
                        </button>
                    </div>

                    <div className="menu-button-holder-4">
                        <button class="button style-light" onClick={onClickCredits}>Credits</button>
                    </div>

                    */}


                </div>

            </div>
        </>
    )
};

export default Menu;