import React, { useRef, useEffect } from 'react';

const LessonButton = ({ state, lessonNb, onClickFunc }) => {


    // consts
    const blueClass = "button style-accent lesson-button";
    const greyClass = "button style-light lesson-button";
    const purpleClass = "button style-purple lesson-button";
    const holderClass = "lesson-button-holder-" + lessonNb.toString();

    // functions




    switch(state) {
        case "blocked":
            return (
                <div className={holderClass}>
                    <button disabled class={greyClass}><i class="fa style-white fa-star fa-2x" aria-hidden="true"/></button>
                </div>
            );
        case "available":
            return (
                <div className={holderClass}>
                    <button class={blueClass} onClick={onClickFunc}><i class="fa style-white fa-star fa-2x" aria-hidden="true"/></button>
                </div>
            );
        case "done":
            return (
                <div className={holderClass}>
                    <button class={purpleClass} onClick={onClickFunc}><i class="fa style-white fa-check fa-2x" aria-hidden="true"/></button>
                </div>
            );
        default:
            return null;
    }
};

export default LessonButton;