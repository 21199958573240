/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2bjuvjwcgndabf2pp76evrmpdm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ulam2vfpujbfhe2vxvip6g3ryy",
    "aws_cognito_identity_pool_id": "us-east-1:3806561a-4e86-442c-9d8b-4e2400517700",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RuDYurlbD",
    "aws_user_pools_web_client_id": "71q6f7nbug1fg4dvea640ksmhm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bucket-assets130140-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
