import React, { useState, useEffect, useRef } from "react";

// js-styles
import Menu from "./js-styles/Menu.js";
import Tree8 from "./js-styles/Tree-8.js";
import Tree12 from "./js-styles/Tree-12.js";
import Credits from "./js-styles/Credits.js";

// number of lessons per vid
// can be computed like Math.floor(lines.length / DIFFR_LINES_NB)
// Elemental 12
// Pinocchio 9
// ZOG 8



import "./App.css";
import "@aws-amplify/ui-react/styles.css";



const App = () => {
  // states
  const [page, setPage] = useState("menu")

  // refs
  const treePinocchio = useRef([])
  if (treePinocchio.current.length === 0) {
    for (let i=0;i<9;i++) {
      treePinocchio.current.push("blocked");
    }
    treePinocchio.current[0] = "available";
    treePinocchio.current[5] = "available";
  }

  const treeElemental = useRef([])
  if (treeElemental.current.length === 0) {
    for (let i=0;i<13;i++) {
      treeElemental.current.push("blocked");
    }
    treeElemental.current[0] = "available";
    treeElemental.current[5] = "available";
  }

  const treeZog = useRef([])
  if (treeZog.current.length === 0) {
    for (let i=0;i<9;i++) {
      treeZog.current.push("blocked");
    }
    treeZog.current[0] = "available";
    treeZog.current[5] = "available";
  }



  function saveStates({ name, states}) {
    switch(name) {
      case "Pinocchio":
        treePinocchio.current = states;
        break;
      case "Elemental":
        treeElemental.current = states;
        break;
      case "ZOG":
        treeZog.current = states;
        break;
    }
  }

  // functions
  function clickPinocchio() {
    setPage("pinocchio");
  }

  function clickElemental() {
    setPage("elemental");
  }

  function clickZog() {
    setPage("zog");
  }

  function clickCredits() {
    setPage("credits");
  }

  function closeTree() {
    setPage("menu");
  }




  switch(page) {
    case "menu":
      return <Menu
        onClickPinocchio={clickPinocchio}
        onClickElemental={clickElemental}
        onClickZog={clickZog}
        onClickCredits={clickCredits}
        />;
    case "pinocchio":
      return <Tree8
        treeName="Pinocchio"
        onCloseTree={closeTree}
        initStates={treePinocchio.current}
        saveStates={saveStates}
        />;
    case "elemental":
      return <Tree12
        treeName="Elemental"
        onCloseTree={closeTree}
        initStates={treeElemental.current}
        saveStates={saveStates}
        />;
    case "zog":
      return <Tree8
        treeName="ZOG"
        onCloseTree={closeTree}
        initStates={treeZog.current}
        saveStates={saveStates}
        />;
    case "credits":
      return <Credits
        onClosePage={closeTree}
        />;
    default:
      return null;
  }
};

//export default withAuthenticator(App);
export default App;