function LessonProgress({ percentage, style }) {
    let perc = percentage;
    switch (true) {
        case perc < 0:
            perc = 0;
            break;
        case perc < 10:
            perc = 0;
            break;
        case perc < 15:
            perc = 10;
            break;
        case perc < 25:
            perc = 15;
            break;
        case perc < 30:
            perc = 25;
            break;
        case perc < 50:
            perc = 30;
            break;
        case perc < 60:
            perc = 50;
            break;
        case perc < 75:
            perc = 60;
            break;
        case perc < 90:
            perc = 75;
            break;
        case perc < 100:
            perc = 90;
            break;
        default:
            perc = 100;
            break;
    }


/* 0p: 0,
  10p: 10%,
  15p: 15%,
  25p: 25%,
  30p: 30%,
  50p: 50%,
  60p: 60%,
  75p: 75%,
  90p: 90%,
  95p: 95%,
  100p: 100%, */

  let stylez;
  switch(style) {
    case "lesson":
        stylez = "";
        break;
    case "boss":
        stylez = "style-red";
        break;
    default:
        stylez = "";
        break;
  }





    //const perc = Math.floor(percentage);
    const class_perc = "progress-bar " + stylez + " width-" + perc.toString() + "p";


    return(
        <div class="progress">
            <div class={class_perc} role="progressbar" aria-valuenow={perc} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    )
}

export default LessonProgress





