import "./css/Credits.css";

const Credits = ({ onClosePage }) => {
    return (
        <>
        <div className="main-page">
            <div className="credits-page">
                <p>Videos belong to Netflix, Disney, and Brut:
                    <br />
                    <a href="https://www.youtube.com/watch?v=L2O5TMO3egI" target="_blank">Pinocchio - Netflix France</a>
                    <br />
                    <a href="https://www.youtube.com/watch?v=qugov_H-_aQ" target="_blank">Zidane, Ocon, Gasly - Brut</a>
                    <br />
                    <a href="https://www.youtube.com/watch?v=k8-2eaL74O0" target="_blank">Elemental, part I - Disney France</a>
                    <br />
                    <a href="https://www.youtube.com/watch?v=a43kzjgx-Vs" target="_blank">Elemental, part II - Disney France</a> 
                    <br />
                    <a href="https://www.youtube.com/watch?v=BDxR5numBxo" target="_blank">Elemental, part III - Disney France</a>
                    <br />
                    <br />
                    Images created by <a href="https://www.flaticon.com/authors/freepik" target="_blank">Freepik on Flaticon</a>.
                    <br />
                    <br />
                    <br />
                    <br />
                    Wbw = word-by-word
                    <br />
                    Comm = comment
                    <br />
                    <br />
                    <br />
                    <button class="button style-light" onClick={onClosePage}>Menu</button>
                </p>

            </div>
        </div>
        </>
    )
};

export default Credits;