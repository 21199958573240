import React, { useState, useEffect, useRef } from "react";

import "./css/Lesson.css";

// js-styles
import LessonProgress from "../js-styles/LessonProgress.js";
import AnswerBox from "../js-styles/AnswerBox.js";

// img
import HeartSrc from "../img/heart.png";

// content

import lines_z from "../content/french/youtube/brut/zog.json";
import lines_e from "../content/french/youtube/disneyFR/elementaire.json";
import lines_p from "../content/french/youtube/netflixFR/pinocchio.json";


// video module
import "../../node_modules/video-react/dist/video-react.css";
import { BigPlayButton, ControlBar, LoadingSpinner, Player } from 'video-react';

import { getUrl } from 'aws-amplify/storage';


const DIFFR_LINES_NB = 5;
const LESSON_LENGTH = 15;

const Lesson = ({ treeName, userLang, lessonNb, onCloseFunc, successFunc }) => {
  // states
  const [userProgress, setUserProgress] = useState(0); // percentage
  const [userHearts, setUserHearts] = useState(3);

  const [lineInd, setLineInd] = useState(1);

  const [hasAnswered, setHasAnswered] = useState(false);

  // refs
  const isAnswerRight = useRef(false);
  const videoRef = useRef();
  const coco = useRef(); // to cancel setTimeout to stop looping the video

  const pre_key = useRef("");
  const lines = useRef();

  /// initialize pre_key & lines
  if (pre_key.current === "") {
    switch(treeName) {
      case "Pinocchio":
        pre_key.current = "french/youtube/netflixFR/pinocchio/";
        lines.current = Object.values(lines_p);
        break;
      case "Elemental":
        pre_key.current = "french/youtube/disneyFR/elementaire/";
        lines.current = Object.values(lines_e);
        break;
      case "ZOG":
        pre_key.current = "french/youtube/brut/zog/";
        lines.current = Object.values(lines_z);
        break;
      default:
        break;
    }
  }

  const NB_LINES = useRef(lines.current.length);

  const urls = useRef([]); // or useMemo?
  const linesNbs = useRef([]); // or useMemo?

  /// initialize linesNbs & urls
  if (linesNbs.current.length === 0) {
    createLinesNbs();
    fetchUrls();
  }

  /// reduce video default volume
  useEffect(() => {
    videoRef.current.volume = 0.75;
  }, []);

  // consts
  /// properties of current loop
  const lineNb = linesNbs.current[lineInd];
  const videoUrl = urls.current[lineInd];
  const rightAnswer = lines.current[lineNb].translation;
  const wbw = lines.current[lineNb].wbw;
  const comment = lines.current[lineNb].comment;

  // for new jsons
  //const rightAnswer = lines.current[lineNb]["lang"][userLingo].translation;
  //const wbw = lines.current[lineNb]["lang"][userLingo].wbw;
  //const comment = lines.current[lineNb]["lang"][userLingo].comment;


  function createLinesNbs() {
    for (let i = 0; i < DIFFR_LINES_NB; i++) {
      linesNbs.current.push(((lessonNb - 1) * DIFFR_LINES_NB + i) % NB_LINES.current);
    }
  }

  async function fetchUrls() {
    for (let i = 0; i < linesNbs.current.length; i++) {
      const url = await getUrl({ key: pre_key.current + lines.current[linesNbs.current[i]].line + ".mp4" });
      urls.current.push(url.url);
      //urls.current.push("https://media.w3.org/2010/05/sintel/trailer_hd.mp4");
    }

    setLineInd(0); // this forces React to load the very first video of user session
  }

  function quitLesson() {
    stopButton();
    onCloseFunc();
  }

  /// video loop control buttons
  function playButton() {
    clearTimeout(coco.current) // in case user press Play when video is already looping
    videoRef.current.play();
    coco.current = setTimeout(() => { loopBuffer(); }, videoRef.current.duration);
  }

  function replayButton() {
    videoRef.current.play();
    coco.current = setTimeout(() => { reloopBuffer(); }, videoRef.current.duration);
  }

  function loopBuffer() {
    coco.current = setTimeout(() => { replayButton(); }, 500);
  }

  function reloopBuffer() {
    coco.current = setTimeout(() => { playButton(); }, 500);
  }

  function stopButton() {
    clearTimeout(coco.current);
    videoRef.current.pause();
  }

  /// for AnswerBox
  function pickRandomLine() {
    let rndLine = Math.floor(Math.random() * NB_LINES.current);
    return(lines.current[rndLine].translation);
    //return(lines.current[rndLine]["lang"][userLingo].translation);
  }

  function isRightAnswer() {
    isAnswerRight.current = true;
    setHasAnswered(true);
  }

  function isWrongAnswer() {
    isAnswerRight.current = false;
    setHasAnswered(true);
  }

  function clickContinue() {
    stopButton();

    if (isAnswerRight.current) {
      let newProgress = userProgress + 100 / LESSON_LENGTH;
      if (newProgress < 100) {
        setUserProgress(newProgress);
      } else {
        successFunc();
      }
    } else {
      if (userHearts > 0) {
        setUserHearts(userHearts - 1);
      } else {
        quitLesson();
      }
    }

    setHasAnswered(false);
    setLineInd((lineInd + 1) % DIFFR_LINES_NB);
  }


  
  return (
    <>
      <div className="main-page">
        <div className="lesson-page">
          <div className="top-line">
            <div className="top-line-close-button-holder-lesson">
              <button class="close-button" aria-label="Close" onClick={quitLesson} />
            </div>
            <div className="top-line-progress">
              <LessonProgress style="lesson" percentage={userProgress} />
            </div>
            <div className="top-line-hearts">
              <img src={HeartSrc} alt="heart"></img>
              <p>{userHearts}</p>
            </div>
          </div>

          <div className="video-div" onContextMenu={e => e.preventDefault()} >
            <Player
              className="video-player"
              ref={videoRef}
              preload="auto"
              fluid={false}
              playsInline
              src={videoUrl}
            >
              <BigPlayButton disabled={true} position="center"></BigPlayButton>
              <LoadingSpinner disabled={true} />
              <ControlBar disabled={true} autoHide={false} disableDefaultControls />
            </Player>
          </div>

          <div className="control-buttons">
            <button class="button style-accent width-30p" onClick={playButton}>Play</button>
            <button class="button style-accent width-30p" onClick={stopButton}>Stop</button>
          </div>
          
          <div className="answer-box">
          <AnswerBox
            hasAnswered={hasAnswered}
            quizType={"pick-3"}
            line1={rightAnswer}
            line2={pickRandomLine()}
            line3={pickRandomLine()}
            rightAnswer={rightAnswer}
            wbw={wbw}
            comment={comment}
            isAnswerRight={isAnswerRight.current}
            funcIfRight={isRightAnswer}
            funcIfWrong={isWrongAnswer}
            funcContinue={clickContinue}
          />
          </div>
        </div>
      </div>
    </>
  );
};

export default Lesson;